* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.webContant {
  width: 100%;
}

.web-project-card-link-web{
  padding-left: 0.75rem !important;
}

.webRow {
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% +20px);
}
.webImg {
  height: auto;
  width: auto !important;
  margin-top: 30px;
  margin-left: 95px;
}
.webImg2 {
  height: 500px ;
  width: auto !important;
  margin-top: 30px;
  margin-left: 95px;
}
.training {
  margin-top: 43px;
}
.training {
  margin-top: 43px;
}
.socialMediaImg {
  height: auto;
  width: 100%;
}

.webTitleContainer {
  max-width: 1190px;
  margin: 50px 0;
  padding: 0 128px 0 0;
}
.webTitle {
  padding: 20px 0;
  color: rgb(81, 42, 173);
}
.wepDesc {
  font-size: 1.1em;
  font-weight: 300;
  color: #767778;
  line-height: 36px;
  font-family: "Mark Pro";
}
.btn {
  border: none;
}
.modal-header {
  padding: 15px 45px !important;
}
.modal-title {
  color: rgb(81, 42, 173);
}
.web-list-items {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.web-list-item {
  display: flex;
  text-align: left;
  position: relative;
  list-style: none;
}
.web-list-icon {
  display: flex;
  align-items: center;
}
.web-list-icon i {
  width: 1.25em;
  font-size: 21px;
  color: rgb(81, 42, 173);
}
.web-list-desc {
  align-self: center;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 300;
  color: #767778;
  font-family: "Mark Pro";
  text-align: left;
}
.web-hr {
  margin: 0.5rem 0 !important;
}
.softwareSupport,
.webImg {
  margin-left: 95px;
}
@media screen and (max-width: 769px) {
  .webTitleContainer {
    padding: 0 30px !important;
    margin: 0 auto;
  }
  .softwareSupport,
  .webImg, .webImg2,
  .socialMediaImg {
    width: 350px !important;
    margin-left: 0 !important;
  }

  .web {
    width: 350px !important;
    margin-top: -41px !important;
  }
  .reklamImg {
    width: 289px !important;
  }
  .webRow {
    margin-left: auto;
    margin-right: auto;
  }
  .training {
    margin-top: 0 !important;
  }
  .training img {
    width: 283px !important;
  }
}
