.contactContainer {
  justify-content: center !important;
  margin-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
ul > li {
  color: black;
  font-size: 16px;
  font-weight: 300;
}
.social-icons li a {
  border: 1px solid #554ce7;
  border-radius: 50%;
  color: white;
  display: inline-block;
  /* font-size: 16px; */
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.6s ease-in-out;
  text-align: center;
  margin-right: 10px;
}
.social-icons-contact a {
  background-color: #fff !important;
  color: #554ce7 !important;
  text-decoration: none;
}
.social-icons-contact a:hover {
  background: #554ce7 !important;
  color: #fff !important;
}
.email-link {
  color: #000;
  font-size: 29px;
  font-family: "Mark Pro Heavy";
}
.formContainer {
  margin: 0;
  font-family: -apple-system, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  padding: 30px;
  padding-bottom: 10px;
  border: none;
  border-radius: 0.25rem;
  max-width: 100%;
  /* box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5); */
  position: inherit;
  z-index: 2;
}
.formBorder {
  display: block;
}
.fcf-form-group {
  margin-bottom: 1rem !important;
}
.fcf-input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.fcf-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4dac0;
  outline: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea.fcf-form-control {
  font-family: -apple-system, Arial, sans-serif;
  height: auto !important;
}
.gifContant {
  position: absolute;
  top: 0;
  z-index: 0;
}
.paperPlane {
  position: absolute;
  width: 80px;
  left: 180px;
  top: 250px;
  animation-duration: 5s;
  animation-name: animatePaper;
}

@keyframes animatePaper {
  0% {
    transform: scale(1) rotate(10deg);
  }
  5% {
    transform: scale(1.25) rotate(-15deg);
  }
  10% {
    transform: scale(1.5) rotate(5deg);
  }
  15% {
    transform: scale(1.75) rotate(-10deg);
  }
  20% {
    transform: scale(2) rotate(0);
  }
  100% {
    transform: scale(20) translate3d(100vw, -100vh, 0) rotate(0);
  }
}
.contact h1,
.map-section h1 {
  font-size: 35px;
  line-height: 52px;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
  background: rgb(81, 42, 173);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact .textContainer span {
  font-weight: 300;
}

.contact .contactSpans {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 53px;
}
/* .formContainer {
  flex: 1;
  position: relative;
} */

/* .formContainer .phoneSvg {
  stroke: #554ce7;
  position: absolute;
  margin: auto;
} */

.formContainer button {
  padding: 0.6rem 0.6rem;
  border: none;
  background: rgb(252, 204, 60);
  cursor: pointer;
  border-radius: 7px;
  color: #676767;
  font-weight: 600;
  font-size: 1rem;
  font-family: "Mark Pro";
}
.adress {
  display: flex;
}
.adressBody {
  font-family: "Mark Pro Heavy" !important;
}
.map-section {
  padding: 0 0 50px 0;
}
.map-container {
  flex-direction: column;
}
.locaTitleContainer {
  display: flex;
  align-items: flex-end;
}
.locaIcon {
  width: 70px;
}
@media screen and (max-width: 769px) {
  .contactContainer {
    justify-content: none !important;
  }
  .email-link {
    font-size: 25px !important;
  }
  .contact h1 {
    font-size: 30px !important;
  }

  .paperPlane {
    width: 80px;
    left: 0 !important;
    top: 300px;
  }
  .contact .contactSpans {
    margin: auto;
  }
  .contact {
    --bs-gutter-x: 0rem !important;
  }
  .textContainer {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
