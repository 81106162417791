.refContainer {
  width: 1309px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.refTitle {
  /* color: #676767; */
  font-family: "Mark Pro Heavy" !important;
  font-size: 2.3rem;
  line-height: 52px;
}
.refhomeleft {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0 !important;
  margin-top: 37px;
}
.appHome {
  padding: 50px 20px;
}
.aloha {
  background: #512aad;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.qrcode {
  width: 120px;
}
.qrContainer {
  width: 132px;
}
.no-padding {
  padding-left: 0;
}
/* .mobile-card {
  cursor: pointer;
  margin: 10px 0;
} */
.card {
  border: 0px !important;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 10px;
}
.card-icon {
  display: flex;
  flex-wrap: wrap;
  width: 70px !important;
}
.card .card-icon {
  margin-left: 5%;
  margin-top: 5%;
}
.icon-listen {
  width: 95px !important;
  margin-left: 0 !important;
}
.icon-shop {
  margin-left: 1% !important;
  width: 120px !important;
  margin-top: 0 !important;
  height: 95px;
}
.icon-coupon {
  width: 84px !important;
  /* height: 95px; */
}
.icon-gift {
  margin-top: 3%;
}
.listen-body {
  padding-top: 0 !important;
}
.card:hover {
  -webkit-box-shadow: 0 10px 90px rgba(21, 110, 103, 0.2);
  box-shadow: 0 10px 90px rgba(68, 21, 110, 0.2);
}
.card-body {
  /* margin: 20px 15px;
  margin-top: 25px;
  padding-right: 65px; */
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.p-ref p {
  font-size: 1.1em;
  color: #767778;
  font-weight: 300;
  margin-top: 0;
}
.ref-h1 {
  margin-bottom: 2.5rem;
}
.row {
  --bs-gutter-x: 1.5rem !important;
}
.mobileImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -webkit-box-shadow: 0 10px 90px rgba(21, 110, 103, 0.2);
  box-shadow: 0 10px 90px rgba(68, 21, 110, 0.2);
  border-radius: 57px;
}
.mobileImage img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  border-style: none;
}
.refContant {
  display: block !important;
}

@media  (max-width: 1400px) {
  .refContainer {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MaTQV{
    width: 1200px !important;
  }
}

@media  (max-width: 1280px) {
  .refContainer {
    width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MaTQV{
    width: 1100px !important;
  }
  
}

@media screen and (max-width: 431px) {
  .dowload {
    padding: 25px 29px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .refContant {
    max-width: 1300px !important;
  }
}
@media screen and (max-width: 769px) {
  .md-hide {
    display: none;
  }

  .MaTQV{
    width: unset !important;
  }

  .refContainer {
    width: auto !important;
    --bs-gutter-x: 0 !important;
  }
  .refTitle {
    margin: 5px 7px;
    text-align: center;
    font-size: 1.9rem;
  }
  .refDesc {
    margin: auto !important;
  }
  .dowload {
    margin: auto;
    padding: 25px 5px;
  }
  .ref-img-container {
    overflow: visible !important;
    height: 324px;
    /* margin-top: 54px; */
  }
  .ref-img-container img {
    height: 304px !important;
  }
  .refMoreBtn {
    margin-top: 15px;
  }
  .mobileImage {
    width: 63% !important;
    border-radius: 29px;
    box-shadow: none !important;
  }
  .mobile-carousel {
    width: 314.2px !important;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  }
  .mobile-card {
    overflow: hidden;
    height: 100%;
    border-radius: 5px;
  }
  .card-title {
    font-size: 1rem !important;
    margin-bottom: 0.75rem !important;
  }
  .card-text {
    font-size: 0.9rem !important;
  }
  .ref-service-menu {
    background-color: #ffff !important;
  }
  .ref-card-icon {
    margin-top: 0 !important;
    width: 70px !important;
    height: auto !important;
  }
  .md-show {
    display: block !important;
  }
  .react-multiple-carousel__arrow {
    min-width: 30px !important;
    min-height: 30px !important;
  }
  .card-body {
    padding-top: 0 !important;
  }
  .card:hover {
    box-shadow: none !important;
  }
  .ref-carousel-container {
    padding: 18px 24px;
    margin: 0 3px;
  }
  .ref-carousel-contant {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(4% + -9px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(4% + -9px) !important;
  }
  /* .react-multi-carousel-item {
    margin-right: 2px !important;
  } */
  /* .icon-gift {
    width: 70px !important;
  } */
}
