.vision-background {
  position: absolute;
  font-size: 15vw;
  font-family: "Mark Pro Heavy";
  color: rgb(186, 171, 223);
  opacity: 0.2;
  white-space: nowrap;
  top: -22px;
  font-weight: 800;
}

@media screen and (max-width: 769px) {
  .vision-background {
    font-size: 10vh !important;
    opacity: 0.4 !important;
    top: -188px !important;
    left: 43px !important;
  }
}
