@media (max-width: 768px) {

    .container .sc-ktwOfi{
        width: 100%;
        text-align: left !important;
        height: unset !important;
    }

    .container .sc-ktwOfi .sc-fmKFGs{
        text-align: left !important;
        padding: 0 15px;
    }

    .container .sc-ktwOfi .sc-hABBmJ {
        width: 100%;
        margin-bottom: unset;
    }

    .container .sc-ktwOfi .sc-hABBmJ img{
        /* width: 100%; */
        height: 250px;
        padding: 0 15px;
    }
}