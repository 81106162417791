.mission-background {
  position: absolute;
  font-size: 15vw;
  font-family: "Mark Pro Heavy";
  color: rgb(186, 171, 223);
  opacity: 0.2;
  white-space: nowrap;
  top: 32px;
  font-weight: 800;
}

@media screen and (max-width: 769px) {
  .mission-background {
    font-size: 9vh !important;
    opacity: 0.4 !important;
    top: 82px !important;
    left: 34px !important;
  }
}
