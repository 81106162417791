body {
  font-family: "Mark Pro";
  background-color: white;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
}

li {
  font-size: 20px;
}
.content {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  /* font-weight: 600; */
}
.container {
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: absolute; */
  top: 0;
  left: 0;
  background-color: #fff;
}
.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 80px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}
.nav-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
}

.container .logo {
  cursor: pointer;
  color: black;
  text-decoration: none;
}
.logo-img {
  width: 180px;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-text {
  text-decoration: none;
  color: #000;
  font-size: 20px;
}
#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
}
/* #navbar {
  display: flex;
  align-items: center;
  margin: 10px 0;
} */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  padding: 10px 0;
}

.dropdown-content a {
  color: rgb(77, 75, 75) !important;
  padding: 12px 12px !important;
  text-decoration: none;
  display: block;
  width: 350px !important;
  font-size: 15px !important;
  letter-spacing: normal !important;
  
}

.dropdown-content a:hover {
  background-color: rgb(81, 42, 173) !important;
  color: #ffff !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}
#navbar li {
  /* margin-right: 23px; */
  position: relative;
  margin: 0 18px;
}
#navbar li a {
  text-decoration: none;
  cursor: pointer;
  color: rgb(81, 42, 173);
  font-size: 16px;
  font-weight: 700;
  transition: 0.2s ease-in-out;
  letter-spacing: 0.5px;
  font-family: "Mark Pro";
  margin: 0;
  padding: 0;
  /* line-height: 105px; */
  background-color: transparent;
  /* text-transform: uppercase; */
}
#navbar li .navbarp {
  text-decoration: none;
  cursor: pointer;
  color: rgb(81, 42, 173);
  font-size: 16px;
  font-weight: 700;
  transition: 0.2s ease-in-out;
  letter-spacing: 0.5px;
  font-family: "Mark Pro";
  margin: 0;
  padding: 0;
  /* line-height: 105px; */
  background-color: transparent;
  /* text-transform: uppercase; */
}
#navbar li a:hover {
  color: rgb(77, 75, 75);
}
#navbar li .navbarp:hover {
  color: rgb(77, 75, 75);
}
#navbar .list a:hover::after {
  content: "";
  width: 50%;
  height: 2px;
  background: linear-gradient(to right, rgb(221, 207, 254), rgb(196, 76, 231));
  position: absolute;
  bottom: -4px;
  left: 2px;
}

#mobile {
  display: none;
}
#mobile i {
  color: #696666;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1540px;
  }
}

@media screen and (max-width: 769px) {
  #navbar {
    top: 80px;
    position: fixed;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.8);
    padding: 23px 0 0 10px;
    z-index: 999;
    background: rgb(81, 48, 179);
    /* transition: 0.5s ease-in-out; */
    transition: transform 0.3s ease-in-out;
  }
  #navbar li a {
    color: #ffff;
  }
  .navbarp {
    color: #ffff !important;
  }
  .languages {
    margin-right: 10px;
  }
  #navbar.active {
    /* right: -10px; */
    display: flex;
    transform: translateY(0);
    height: 309px;
  }
  .dropdown-content {
    width: 247px;
    overflow-y: scroll;
    max-height: calc(100vh - 233px);
  }
  .dropdown-content a {
    color: rgb(81, 42, 173) !important;
  }
  #navbar li {
    margin-bottom: 25px;
  }
  #navbar li a:hover {
    color: white;
  }
  /* #navbar li a:hover::after {
    content: "";
    width: 50%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    bottom: -4px;
    left: 2px;
  } */

  #mobile {
    display: block;
  }
  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
  .dropdown-content a {
    width: auto !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 435px) {
  .logo-img {
    width: 136px !important;
  }
  .nav-container {
    padding: 16px 0 !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .nav-container {
    width: max-content;
    padding: 0;
  }
  .content {
    width: max-content;
  }
  #navbar li {
    margin: 0 12px;
  }
  #navbar li a {
    font-size: 13px;
  }
  #navbar li .navbarp {
    font-size: 14px;
  }
}
