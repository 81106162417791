body {
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; */
  background-color: white;
  /* font-weight: 400; */
  overflow-x: hidden;
  position: relative;
  padding-right: 0 !important;
}
.home-title {
  /* font-family: "Work Sans", Sans-serif; */
  color: #676767;
  font-family: "Work Sans", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 52px;
  margin: 0;
}
.bloc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.react-multiple-carousel__arrow--right {
  right: calc(4% + -35px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + -40px) !important;
}

.desc {
  font-size: 1.1em;
  font-weight: 300;
  color: #767778;
  line-height: 36px;
  font-family: "Mark Pro";
}

.home-desc-left {
  padding-left: 172px;
  width: 50% !important;
}

.title-content {
  background: rgb(87, 48, 179) !important;
}
.titleContainer {
  margin-top: 100px;
}
.row {
  --bs-gutter-x: 0rem !important;
}

.service-tab-menu {
  display: flex;
  margin: 0 0 60px;
  justify-content: center;
  pad: 30px;
  flex-wrap: wrap;
  /* background: rgb(210, 196, 253); */
}
.service-tab-menu .btn-title {
  display: block;
  margin: 0 0 6px;
  color: #1a1a1b;
  transition: all ease 0.4s;
  font-family: "Mark Pro Heavy";
  font-size: 1.1rem;
  line-height: 1;
  letter-spacing: 0.3ps;
}
.service-tab-menu .btn-text {
  color: #55595d;
  transition: all ease 0.4s;
  display: block;
}
.subtitle2 {
  text-transform: capitalize;
  line-height: 1.35;
  margin: -0.25em 0 40px;
  color: transparent;
  -webkit-text-stroke: 1px #ffff;
  padding-top: 20px;
  font-family: "Mark Pro";
  /* font-size: 2.3rem; */
}
.h6 {
  font-size: 20px;
  text-transform: none;
  font-weight: 700;
  line-height: 1.5;
}

.elementor-content {
  background-color: #512aad00;
}
.elementor-content a {
  text-decoration: none;
  /* font-family: "Work Sans", Sans-serif; */
}

.elementor-button-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.hr {
  color: #554ce7;
  /* border: 2px solid #554ce7; */
}

@media screen and (max-width: 769px) {
  .text {
    margin-top: 20px !important;
  }
  .title-content {
    user-select: none;
    transform: none !important;
  }

  .service-tab-menu .col {
    max-width: 330px !important;
  }
  .row {
    overflow: hidden !important;
    margin-right: 0 !important;
  }
  .bloc {
    margin-top: auto;
    height: auto !important;
  }
  .home-title {
    font-size: 28px !important;
    padding: 0 20px !important;
  }
  .desc {
    line-height: 30px !important;
    /* padding: 0 20px !important; */
    font-size: 18px !important;
  }
  .applogo {
    width: 95px !important;
  }

  .home-desc {
    transform: translateX(6px) translateY(0px) !important;
    width: auto !important;
    order: 1;
    padding-left: 22px;
  }
  .home-desc-left {
    padding-left: 0;
  }
  .elementor {
    margin-top: 95px;
  }

  .subtitle2 {
    font-size: 30px;
  }
  .react-multi-carousel-track {
    padding: 5px 0 !important;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: none;
  }
}
@media screen and (max-width: 435px) {
  .service-tab-menu {
    margin: 10px 0 60px !important;
  }
  .container {
    padding: 0 !important;
  }
  .service-tab-menu .btn-title {
    font-size: 15px !important;
  }
  .react-multi-carousel-list {
    padding-top: 10px !important;
  }
  .desc {
    width: 350px !important;
  }
}
