body {
  background-color: white;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  padding-right: 0 !important;
}
.homeTitle {
  color: #676767;
  font-family: "Mark Pro Heavy" !important;
  font-size: 2.3rem;
  line-height: 52px;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.homeTitle p {
  margin: 0;
}

.homeContant {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.homeContainer {
  width: 1309px;
  display: flex;
  justify-content: space-between;
}
.homeleft {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.img-container {
  height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-container2 {
  height: 288px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-container2 img {
  margin-left: 80px;
}
.img-container3 {
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-container4 {
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-btn {
  background-color: rgb(252, 204, 60) !important;
  color: #676767;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 0.4rem;
  padding: 0.8rem 0.8rem;
  cursor: pointer;
  border: none;
  font-family: "Mark Pro";
}
.homeDesc {
  font-size: 1.1em;
  font-weight: 300;
  color: #767778;
  line-height: 36px;
  font-family: "Mark Pro";
}
.home-desc {
  width: 45%;
  z-index: 1;
}
.home-desc-left {
  padding-left: 172px;
  width: 50% !important;
}
.home-desc-p {
  background: #512aad;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-transform: capitalize;
}

.titleContainer {
  margin-top: 100px;
}
.row {
  --bs-gutter-x: 0rem !important;
}
.nav-link-btn {
  border: none;
  flex: 1;
  width: 300px;
  height: 169px;
  display: inline-block;
  text-align: center;
  margin: 60px 0 40px;
  padding: 0 10px 38px;
  background-color: #ffff;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5) !important;
  border-radius: 5px;
  min-width: 210px;
  transition: all ease 0.4s;
}

.nav-link-btn .btn-img-container {
  display: block;
  width: 120px;
  height: 120px;
  line-height: 100px;
  background: rgb(162, 140, 213);
  border: 10px solid #ffff;
  border-radius: 50%;
  margin: -60px auto 18px;
  transition: all ease 0.4s;
}
.img-container .homeImg {
  width: 355px;
  height: 400px;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: animate 2s infinite ease alternate;
}

.img-container .homeImg2 {
  
  height: 500px;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: animate 2s infinite ease alternate;
}
@keyframes animate {
  to {
    transform: translateY(20px);
  }
}
.nav-link-btn .btn-img {
  width: 100%;
}
.nav-link-btn:hover {
  /* background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.4),
    rgb(105, 72, 185)
  ); */
  background: rgb(105, 72, 185);
  transform: scale(0.95);
  transition: all ease-in-out 0.8s;
}
.nav-link-btn:hover .btn-title {
  color: #ffff;
  transition: all ease-in-out 0.3s;
}
.service-tab-menu {
  display: flex;
  margin: 0 0 60px;
  justify-content: center;
  pad: 30px;
  flex-wrap: wrap;
  background-color: rgb(87, 48, 179) !important;
}

.service-tab-menu .btn-text {
  color: #55595d;
  transition: all ease 0.4s;
  display: block;
}
.h6 {
  font-size: 20px;
  text-transform: none;
  font-weight: 700;
  line-height: 1.5;
}
.title-area {
  text-align: center;
  padding-top: 30px;
}

.subtitle3 {
  text-transform: capitalize;
  line-height: 1.35;
  margin: -0.25em 0 25px;
  color: rgb(81, 42, 173);
  padding-top: 20px;
}

.arrowIcon {
  color: rgb(81, 42, 173);
  font-size: 22px;
  animation: arrowAnimation 1s infinite ease-in-out;
}
@keyframes arrowAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0);
  }
}

.elementor-content {
  background-color: #512aad00;
}
.elementor-content a {
  text-decoration: none;
}
.elementor-button {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #512aad00;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
}
.elementor-button-content-wrapper {
  display: flex;
  justify-content: flex-start;
}
.elementor-button-icon {
  margin-left: 7px;
  display: flex;
  align-items: center;
}

.elementor-button-text {
  display: inline-block;
  font-size: 19px;
  font-weight: 600;
  text-transform: capitalize;
  background: rgb(81, 42, 173);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.project-title {
  background: rgb(81, 42, 173);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Mark Pro Heavy";
}

@media screen and (max-width: 991px) {
  .nav-link-btn {
    min-width: 45% !important;
  }
}

@media screen and (max-width: 769px) {
  .homeContainer {
    width: auto !important;
    margin-top: 0 !important;
  }
  .homeTitlep {
    margin-top: -10px !important;
  }
  .titleContainer {
    margin: 0 !important;
  }
  .title-area {
    padding-top: 0 !important;
  }
  .img-container2 img {
    margin-left: 106px;
  }
  .img-container {
    height: 316px;
    margin-top: 27px;
    margin-bottom: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -75px;
  }
  .img-container2 {
    height: 234px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -222px;
  }
  .img-container3 {
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 303px;
  }
  .img-container4 {
    margin-left: -183px;
    height: 233px;
  }
  .homeleft,
  .img-container,
  .img-container2 {
    width: 100%;
    max-width: 100%;
  }

  .homeleft {
    /* padding: 0 20px; */
    padding-left: 28px;
  }

  .img-container,
  .img-container2,
  .img-container3,
  .img-container4 {
    overflow: hidden;
  }

  .btn-content {
    text-align: start;
  }
  .homeContant {
    margin-top: auto;
    height: auto !important;
  }
  .homeTitle {
    font-size: 28px !important;
    margin-top: 23px !important;
  }
  .homeDesc {
    line-height: 30px !important;
    font-size: 18px !important;
  }

  .home-desc {
    width: auto !important;
    order: 1;
  }
  .home-desc-left {
    padding-left: 0;
  }
  .elementor {
    margin-top: 95px;
  }

  .subtitle2 {
    font-size: 30px !important;
    margin: 0 !important;
  }
  .akademi-title {
    text-transform: none !important;
    padding-top: 0 !important;
  }
  .akademiTitle {
    line-height: 44px;
    font-size: 25px !important;
    margin-top: 0 !important;
    text-transform: capitalize;
  }
  .modal-body {
    text-align: left !important;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: none;
  }
}
@media screen and (max-width: 768px) {
  .nav-link-btn {
    width: 100% !important;
    margin: 49px 0px !important;
  }
  .service-tab-menu {
    margin: 10px 0 60px !important;
  }
  .container {
    padding: 0 !important;
  }
  .service-tab-menu .btn-title {
    font-size: 15px !important;
  }
  .homeDesc {
    width: 350px !important;
    line-height: 30px !important;
    font-size: 18px !important;
  }
  .img-container .homeImg {
    width: 247px !important;
  }
  .img-container .homeImg2 {
    width: 247px !important;
  }
}
/* @media screen and (max-width: 391px) {
  .nav-link-btn {
    margin: 49px 25px !important;
  }
}
@media screen and (max-width: 376px) {
  .nav-link-btn {
    margin: 49px 7px !important;
  }
}
@media screen and (min-width: 412px) and (max-width: 500px) {
  .nav-link-btn {
    margin: 49px 35px !important;
  }
} */

@media screen and (min-width: 768px) and (max-width: 1014px) {
  .homeContant {
    flex-direction: row;
  }
  .img-container {
    transform: translateX(6px) translateZ(0px) !important;
  }
}
