.web-projects-container {
    padding: 50px 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 20px;
}

.web-projects-title{
    margin-top: 60px;
    text-align: center;
}

.web-project-card {
    position: relative;
    display: inline-block;
    /* box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1); */
    border-radius: 10px;
    padding: 10px 10px;
    transition: all 0.3s;
    width: min-content;
}

.web-project-card:hover {
    background-color: #EBEBEB;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.web-projects-image-container{
    display: flex;
}

.web-project-card-image {
    height: auto;
    width: 530px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
}

.web-project-card-content{
    display: flex;
    height: 210px;
    flex-direction: column;
    justify-content: space-between;
}

.web-projects-summary{
    /* width: 150px; */
    font-weight: 300;
  color: #767778;
    box-sizing: border-box;
    font-size: 15px;
    padding: 0px 10px;
    line-height: 30px;
}

.web-project-card-title {
    position: relative;
    /* bottom: 25px;  */

    left: 10px;
    /* padding: 10px 15px; */
    /* font-size: 24px; */
    color: #6948B9;
    border-radius: 5px;
}

.web-project-card-link {
    position: relative;
    left: 10px;
    

}


.web-projects-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: opacity 0.3s ease;
}

.web-projects-modal.opening {
    opacity: 0;
    transform: scale(0.95);
    animation: fadeIn 0.3s forwards ease-in-out;
}


.web-projects-modal.closing {
    animation: fadeOut 0.3s forwards ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}

.web-projects-modal-content {
    position: relative;
    background-color: #fefefe;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #aaa;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    
}

.modal-image {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 10px;
}

@media (max-width: 1512px) {


    .web-project-card-image {
        width: 480px;

    }

    .web-projects-container {
        padding: 50px 150px;
    }

}

@media (max-width: 1367px) {


    .web-project-card-image {
        width: 450px;

    }

    .web-projects-container {
        padding: 50px 125px;
    }

    .web-project-card-content{
        height: 250px;
    }

}

@media (max-width: 1280px) {


    .web-project-card-image {
        width: 400px;

    }

    .web-projects-container {
        padding: 50px 125px;
    }

    .web-project-card-content{
        height: 250px;
    }

}

@media (max-width: 1100px) {


    .web-project-card-image {
        width: 350px;

    }

    .web-projects-container {
        padding: 50px 75px;
    }

    .web-project-card-content{
        height: 270px;
    }

}

@media (max-width: 768px) {


    .web-project-card-image {
        width: 350px;

    }

    .web-projects-container {
        padding: 50px 75px;
    }

    .web-project-card-content{
        height: auto;
    }

}

@media (max-width: 480px) {
    .web-projects-container {
        padding: 50px 20px;
    }

    .web-project-card{
        width: 100%;
    }

    .web-project-card-image {
        width: 100%;
    }

    .web-project-card-content{
        width: 100%;
    }

    .web-projects-summary{
        /* width: 150px; */
        font-size: 18px;
        padding: 0px 10px;
        line-height: 30px;
    }

}