body {
  font-family: "Mark Pro";
  background-color: white;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
}

.footer-store {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
}

@media screen and (max-width: 769px) {
  .footer-container {
    position: relative !important;
  }
  .left {
    justify-content: center !important;
    padding: 0 !important;
  }
  .right {
    justify-content: center !important;
    padding: 0 !important;
  }
  .right-desc {
    margin: 0 !important;
  }
}
