.portfolio {
  position: sticky;
}

.about-progress {
  position: sticky;
  top: 66px;
  left: 0;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  text-align: center;
  color: rgb(81, 42, 173);
  font-size: 36px;
  background-color: rgb(255, 255, 255);
  z-index: 3;
}
.about-progressBar {
  height: 10px;
  background: rgb(81, 42, 173);
}
.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: 80px;
}
.wrapperTeam {
  max-width: 1309px;
  height: 100%;
  gap: 50px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 50px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.wrapperContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.wrapperTeam .imageContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 2;
  height: 30%;
  padding: 30px 30px 30px 30px;
  /* border-style: solid;
  border-width: 8px 8px 8px 8px;
  border-color: #512aad;
  border-radius: 200px 200px 200px 200px; */
}
/* .team-social-container{
  margin: 0 auto;
} */
.about-container img {
  height: auto;
  max-width: 100%;
  border: none;
  box-shadow: none;
  width: 179px;
  background: linear-gradient(180deg, #512aad 31%, #ffffff 100%);
  border-radius: 50%;
}
.about-container .textContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  grid-area: 30px;
}
.about-container .textContainer h2 {
  font-size: 72px;
}
.about-container .textContainer p {
  color: #767778;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 33px;
  font-family: "Mark Pro";
}
.textContainer p {
  color: #767778;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  font-family: "Mark Pro";
}
.span-container {
  position: relative;
  font-weight: bold;
  font-size: 20px;
}
.imageContainer .social-icons1 {
  background-color: transparent !important;
  color: #a6b9cc !important;
  border: 1px solid #edeef2 !important;
  border-radius: 5px !important;
  padding: 10px;
  position: relative;
  display: inline-flex;
  text-decoration: none;
  line-height: 40px;
  font-size: 18px;
  transition: all ease-in-out 0.5s;
}
.imageContainer .socialIconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.imageContainer .social-icons1:hover {
  color: #fff !important;
  background: rgb(91, 76, 231) !important;
}
.mision-img {
  width: 303px;
  display: block;
  margin: 0 auto;
}
.vision-img {
  width: 303px;
  display: block;
  margin: 0 auto;
  margin-top: -19px;
}

.mision,
.vizion {
  /* font-weight: 500; */
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #212529;
  line-height: 1;
  font-family: "Mark Pro Heavy";
}
.misionCol {
  padding-bottom: 50px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Mark Pro Heavy";
  margin-top: 0;
  line-height: 1;
}

.text-blue {
  color: #184577 !important;
  font-size: 1.1em;
  font-family: "Mark Pro";
}
.text-height-fixed {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-pack: flex-end;
  line-clamp: 3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1.1rem;
  line-height: 1.5rem;
  height: auto;
  max-height: 4.5rem;
}
.team-card {
  cursor: pointer;
  position: relative;
  padding: 0 20px !important;
}
.team-members img {
}

.about-title {
  margin: 0 auto;
}
.about-title-h1 {
  font-weight: 600;
  color: #212529;
  /* font-family: "Work Sans", Sans-serif; */
  font-size: 40px;
}
.team-text {
  display: block;
}
@media screen and (max-width: 769px) {
  .wrapper,
  .wrapperTeam {
    flex-direction: column;
    padding: 5px 10px !important;
  }
  .wrapperTeam {
    margin-bottom: 0;
  }
  .textContainer {
    transform: none !important;
    padding: 0 14px 20px;
  }
  .about-container {
    margin-top: 0;
  }
  .about-container .wrapperTeam .imageContainer {
    padding: 20px 12px 20px 12px;
  }
  .about-container img {
    width: 131px !important;
  }
  .team-social-container {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-left: 18px;
  }

  .wrapperMision {
    flex-direction: column-reverse !important;
  }
  .wrapperContainer {
    margin-top: 0 !important;
  }
  .misionCol {
    margin-bottom: 0 !important;
  }
  .mision-img,
  .vision-img {
    width: 240px !important;
  }
  .about-progress {
    padding-top: 40px;
  }
  .section-container {
    margin-top: 37px;
  }
}
