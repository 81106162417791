.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}

/* Whatsapp icon css */
.whats-float {
  position: fixed;
  transform: translate(108px, 0px);
  bottom: 5%;
  right: 0;
  width: 156px;
  overflow: hidden;
  background-color: rgb(81, 42, 173);
  color: #fff;
  border-radius: 6px 0 0 6px;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  vertical-align: middle;
  border: 3px solid white;
}
.frame-contact-btn {
  position: fixed;
  bottom: 6%;
  z-index: 9999;
  right: 64px;
  transition-property: opacity;
  box-shadow: rgba(0, 18, 46, 0.18) 0px 2px 20px 0px;
  background: rgb(255, 255, 255);
  white-space: nowrap;
  font-size: 17px;
  /* line-height: 17px; */
  border-radius: 16px;
  padding: 10px 15px;
  height: 42px;
  text-decoration: none;
  color: rgb(81, 42, 173);
}
.emoji {
  width: 23px;
  margin-left: 10px;
  user-select: none;
}
.whats-float a span {
  color: white;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 10px;
  position: absolute;
  line-height: 16px;
  font-weight: bolder;
}

.whats-float i {
  font-size: 30px;
  color: white;
  line-height: 30px;
  padding: 10px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  text-align: center;
}

.whats-float:hover {
  color: #ffffff;
  transform: translate(0px, 0px);
}

.whats-float:hover i {
  transform: rotate(360deg);
}

@media screen and (max-width: 769px) {
  .frame-contact {
    display: none;
  }
}
